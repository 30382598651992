.places-to-visit-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 1440px;
    margin: auto;
}

.places-to-visit-section h1 {
    font-size: 30px;
    text-transform: uppercase;
}

.places-to-visit-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.places-to-visit-card {
    width: 500px;
    height: 400px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.places-to-visit-card1 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%),
        url('../assets/places-to-visit/Pancheshwar-Mahadev-Temple.webp') no-repeat;
    background-size: cover;
    background-position: center;
}

.places-to-visit-card2 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%),
        url('../assets/places-to-visit/mayawati-ashram.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.places-to-visit-card3 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%),
        url('../assets/places-to-visit/banasur-fort-lohaghat.jpg') no-repeat;
    background-size: cover;
    background-position: center;
}

.places-to-visit-card4 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%),
        url('../assets/places-to-visit/abbott-mount.jpg') no-repeat;
    background-size: cover;
    background-position: center right;
}

.places-to-visit-card5 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%),
        url('../assets/places-to-visit/abbott-mount-church.webp') no-repeat;
    background-size: cover;
    background-position: center;
}

.places-to-visit-card6 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%),
        url('../assets/places-to-visit/Champawat.jpg') no-repeat;
    background-size: cover;
    background-position: top center;
}

.places-to-visit-card7 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%),
        url('../assets/places-to-visit/arjuneshwar-temple.jpg') no-repeat;
    background-size: cover;
    background-position: top center;
}

.places-to-visit-card8 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%),
        url('../assets/places-to-visit/baleshwar-temple-champawat.jpg') no-repeat;
    background-size: cover;
    background-position: top center;
}

.places-to-visit-card:hover {
    transform: translateY(-10px);
}

.places-to-visit-card-content {
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 20px;
}

.places-to-visit-card-content h2 {
    padding-bottom: 10px;
    font-family: "cinzel", "Poppins";
}

.places-to-visit-card-content p {
    border-top: 1.5px solid rgba(255, 255, 255, 0.7);
    padding-top: 10px;
}

/* Responsive */
@media (max-width: 480px) {
    .places-to-visit-card {
        width: 100%;
        height: 500px;
    }

    .places-to-visit-section {
        padding: 10px;
    }

    .places-to-visit-section h1 {
        font-size: 25px;
    }

}